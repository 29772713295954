// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '../app/core/interfaces/environment';

export const environment: Environment = {
  production: false,
  contractAddress: '0x98c3F7da48cEC57626855ADD787126D7561a3787',
  networkDetails: {
    blockExplorerUrls: ['https://devnet-explorer.gather.network/'],
    chainId: '0x1cfb18cf',
    chainName: 'Gather Devnet',
    nativeCurrency: {
      name: 'GTH',
      symbol: 'GTH',
      decimals: 18
    },
    rpcUrls: ['https://devnet.gather.network/']
  },
  maxRecordsLimit: 500
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
