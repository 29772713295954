<mat-toolbar>
  <span>Gather Coin Multisend</span>

  <ng-container *ngIf="wallet$ | async as wallet else connectBtn">
    <span class="wallet">{{wallet | ellipsis:'6:6'}}</span>
  </ng-container>
</mat-toolbar>

<div class="container">
  <ng-container *ngIf="wallet$ | async as wallet else connectBlock">
    <p>Your wallet: <b>{{wallet}}</b></p>
    <br>
    <p>Your balance: <b>{{balance$ | async | displayBalance}} GTH</b></p>
    <br>
    <br>
    <div class="field-wrapper">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Recipients and amounts</mat-label>
        <textarea matInput rows="20" [(ngModel)]="inputData" placeholder="Supported formats:&#10;0x314ab97b76e39d63c78d5c86c2daf8eaa306b182 3.141592&#10;0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a,2.7182&#10;0x141ca95b6177615fb1417cf70e930e102bf8f584=1.41421"></textarea>
        <mat-hint>Recommended limit: {{recordsLimit}} records.</mat-hint>
      </mat-form-field>

      <div class="buttons">
        <button mat-flat-button color="primary" [disabled]="!inputData" (click)="confirm()">Continue</button>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #connectBtn>
  <button
    mat-flat-button
    color="primary"
    (click)="connect()"
    [disabled]="loading$ | async"
  >Connect Wallet</button>
</ng-template>

<ng-template #connectBlock>
  <br><br>
  <h2>Connect your Metamask wallet</h2>
  <br>
  <div [ngTemplateOutlet]="connectBtn"></div>
</ng-template>
