<div mat-dialog-content>
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="wallet">
      <mat-header-cell *matHeaderCellDef> Wallet </mat-header-cell>
      <mat-cell *matCellDef="let item"> <span>{{item.wallet}}</span> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
      <mat-cell *matCellDef="let item"> <span>{{item.amount}}</span> </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<div mat-dialog-actions>
  <div class="row">
    <p>Balance: <b>{{balance$ | async | displayBalance | number:'.0-6'}}</b></p>
    <p>Total: <b>{{payAmount | displayBalance | number:'.0-6'}}</b></p>
  </div>

  <ng-container *ngIf="remaining$ | async as remaining">
    <div class="row" *ngIf="remaining < 0">
      <p>Remaining: <b class="color-danger">{{remaining}}</b></p>
    </div>
  </ng-container>

  <div class="buttons row">
    <button type="button" [disabled]="loading$ | async" mat-stroked-button (click)="close()">Cancel</button>
    <button type="button" [disabled]="(loading$ | async) || (remaining$ | async) < 0" mat-flat-button color="primary" (click)="pay()">Confirm</button>
  </div>
</div>
