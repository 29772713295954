import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';
import detectEthereumProvider from '@metamask/detect-provider';
import { NotificationsService } from '../notifications/notifications.service';
import { ExternalProvider, TransactionReceipt, TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { BigNumber, ethers } from 'ethers';
import { environment } from '../../../../environments/environment';
import { ABI } from '../../constansts/abi';


@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private wallet$: BehaviorSubject<string | null>;
  private balance$: BehaviorSubject<BigNumber>;
  private provider: Web3Provider | undefined;

  constructor(private notification: NotificationsService) {
    this.wallet$ = new BehaviorSubject<string | null>(null);
    this.balance$ = new BehaviorSubject<BigNumber>(BigNumber.from(0));
  }

  getProvider(): Observable<Web3Provider> {
    if (this.provider) {
      return of(this.provider);
    }

    return from(detectEthereumProvider()).pipe(
      map(provider => {
        if (provider) {
          return provider as ExternalProvider;
        }

        this.notification.error('Metamask is not installed.', 'Install').afterDismissed().subscribe(
          () => window.open('https://metamask.io/download/', '_blank')
        );

        throw new Error('Metamask is not installed.')
      }),
      switchMap(provider => this.checkProviderNetwork(provider)),
      map(provider => new ethers.providers.Web3Provider(provider)),
      tap(provider => this.provider = provider)
    )
  }

  getWallet(): Observable<string | null> {
    return this.wallet$.asObservable();
  }

  getBalance(): Observable<BigNumber> {
    return this.balance$.asObservable()
  }

  connectWallet(): Observable<any> {
    return this.getProvider().pipe(
      switchMap((provider) => this.getWalletAddress(provider)),
      switchMap(([wallet]) => this.getAccountBalance(wallet)),
      switchMap(() => this.setProviderListeners())
    );
  }

  send(wallets: string[], amounts: string[], value: BigNumber): Observable<TransactionReceipt> {
    return this.getProvider().pipe(
      switchMap(provider => {
        const contract = new ethers.Contract(environment.contractAddress, ABI, provider.getSigner());

        return from(contract.estimateGas['disperseGTH'](
          wallets,
          amounts.map(ethers.utils.parseEther),
          {value}
        )).pipe(
          switchMap(gasLimit => from(contract['disperseGTH'](
            wallets,
            amounts.map(ethers.utils.parseEther),
            {value, gasLimit}
          ))),
        ) as Observable<TransactionResponse>

      }),
      switchMap((r) => r.wait()),
      catchError(err => {

        if (err.data && err.data.message.includes('gas required exceeds allowance')) {
          this.notification.error('Max gas limit is 8000000. Try to reduce the number of recipients.', 'OK');
        }

        throw new Error(err.message);
      })
    )
  }

  private getWalletAddress(provider: Web3Provider): Observable<string> {
    if (provider.provider.request) {
      return from(provider.provider.request({method: 'eth_requestAccounts'})).pipe(
        tap(([wallet]) => { this.wallet$.next(wallet) })
      );
    }

    throw new Error('Unable to get wallet address.');
  }

  private getAccountBalance(walletAddress: string): Observable<BigNumber> {
    return this.getProvider().pipe(
      switchMap(p => from(p.getBalance(walletAddress))),
      tap(b => this.balance$.next(b))
    );
  }

  private checkProviderNetwork(provider: any): Observable<any> {
    const network = environment.networkDetails;
    if (provider.chainId === network.chainId) {
      return of(provider);
    }

    return from(provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{chainId: network.chainId}]
    })).pipe(
      catchError((err) => {
        /**
         * https://github.com/MetaMask/metamask-mobile/issues/3312
         * Metamask app does not throw 4902 err in err.code
         * */
        if (err.code === 4902 || err?.data?.originalError?.code === 4902) {
          return from(provider.request({
            method: 'wallet_addEthereumChain',
            params: [network]
          }));
        }

        throw new Error(err.message);
      }),
      switchMap(() => from(detectEthereumProvider()))
    );
  }

  private setProviderListeners(): Observable<Web3Provider> {
    return this.getProvider().pipe(
      map(p => {
        (p.provider as any).on('accountsChanged', () => window.location.reload());
        (p.provider as any).on('networkChanged', () => window.location.reload());
        return p;
      })
    )
  }
}
