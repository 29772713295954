import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, finalize, map, Observable, of, switchMap } from 'rxjs';
import { BigNumber } from 'ethers';
import { ethers } from 'ethers/lib.esm';
import { WalletService } from '../../services/wallets/wallet.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { environment } from '../../../../environments/environment';


export interface Item {
  wallet: string;
  amount: string;
}

@Component({
  selector: 'app-confirm-component',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  dataSource: MatTableDataSource<Item>;
  displayedColumns = ['wallet', 'amount'];

  balance$: Observable<BigNumber>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  remaining$: Observable<number>;
  payAmount: BigNumber;

  constructor(
    private service: WalletService,
    private notification: NotificationsService,
    private ref: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {list: Item[], balance: Observable<BigNumber>}
  ) {
    this.dataSource = new MatTableDataSource<Item>(data.list);
    this.balance$ = data.balance || of(BigNumber.from(0));
    this.payAmount = data.list.reduce((acc, curr) => acc.add(ethers.utils.parseEther(curr.amount)), BigNumber.from(0));
    this.remaining$ = this.balance$.pipe(
      map(bal => {
        const diffWei = bal.sub(this.payAmount);
        return Number(ethers.utils.formatEther(diffWei));
      })
    );
  }

  pay(): void {
    this.loading$.next(true);
    const wallets = this.data.list.map(({wallet}) => wallet);
    const amounts = this.data.list.map(({amount}) => amount);

    // FIXME: refactor
    this.service.send(wallets, amounts, this.payAmount).pipe(
      finalize(() => this.loading$.next(false)),
    ).subscribe((receipt) => {
      this.notification.success('You have successfully sent GTH.', 'Check').afterDismissed().subscribe(() => {
        window.open(`${environment.networkDetails.blockExplorerUrls}tx/${receipt.transactionHash}`, '_blank')
      })
      this.close(true);
    });
  }

  close(paid = false): void {
    this.ref.close(paid);
  }
}
