import { Component } from '@angular/core';
import { BehaviorSubject, filter, finalize, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { WalletService } from './core/services/wallets/wallet.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, Item } from './core/partials/confirm/confirm.component';
import { parseValues } from './core/utils/parse-values';
import { NotificationsService } from './core/services/notifications/notifications.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  wallet$: Observable<string | null>;
  balance$: Observable<any>;
  inputData!: string;
  readonly recordsLimit = environment.maxRecordsLimit;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private service: WalletService,
    private dialog: MatDialog,
    private notifications: NotificationsService
  ) {
    this.wallet$ = this.service.getWallet().pipe(
      shareReplay(1)
    );

    this.balance$ = this.service.getBalance();
  }

  connect(): void {
    this.loading$.next(true);
    this.service.connectWallet().pipe(
      finalize(() => {this.loading$.next(false)})
    ).subscribe();
  }

  confirm(): void {
    const data: Item[] = this.getParsedData(this.inputData);

    if (data.length === 0) {
      return;
    }

    this.dialog.open(ConfirmComponent, {
      data: {
        list: data,
        balance: this.balance$
      },
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed().pipe(
      filter(r => r),
      // FIXME: made to update balance
      switchMap(() => this.service.connectWallet())
    ).subscribe(() => {
      this.inputData = '';
    });
  }

  private getParsedData(raw: string): Item[] {
    let data: Item[] = [];
    try {
      data = parseValues(raw);
    } catch (err) {
      // @ts-ignore
      this.notifications.error(err.message, 'OK');
    }
    return data;
  }
}
