import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar/snack-bar-ref';
import { TextOnlySnackBar } from '@angular/material/snack-bar/simple-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private snackbar: MatSnackBar) { }

  success(msg: string, action: string | undefined = undefined, duration: number = 0): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar.open(msg, action,{
      duration,
      announcementMessage: 'Success',
      panelClass: ['success'],
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  error(msg: string, action: string | undefined = undefined, duration: number = 0): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar.open(msg, action,{
      duration,
      panelClass: ['error'],
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  info(msg: string, action: string | undefined = undefined, duration: number = 0): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar.open(msg, action,{
      duration,
      panelClass: ['info'],
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }
}
