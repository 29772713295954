import { Item } from '../partials/confirm/confirm.component';
import { ethers } from 'ethers';

export const parseValues = (raw: string): Item[] => {
  return raw.trim().split('\n').map((row, index) => {
    const [wallet, amount, ...rest] = row.split(/ |,|=/);
    if (!wallet || !amount || rest.length) {
      throw new Error(`Cannot process data: "${row}" on line ${index + 1}.`);
    }

    if (!ethers.utils.isAddress(wallet)) {
      throw new Error(`Value "${wallet}" is not a wallet address on line ${index + 1}.`);
    }

    if (isNaN(Number(amount))) {
      throw new Error(`Value "${amount}" is not a number on line ${index + 1}.`)
    }

    if (Number(amount) < 0) {
      throw new Error(`Only positive number is allowed. Got ${amount} on line ${index + 1}`)
    }

    return {wallet, amount}
  });
}
