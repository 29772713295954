import { Pipe, PipeTransform } from '@angular/core';
import { ethers, BigNumber } from 'ethers';

@Pipe({
  name: 'displayBalance'
})
export class DisplayBalancePipe implements PipeTransform {

  transform(value: BigNumber | null, ...args: unknown[]): string {
    if (value) {
      return ethers.utils.formatEther(value);
    }

    return '';
  }
}
